@import "../App";

.box {
  display: flex;
  background-color: $clr--white;
  flex-basis: calc(50% - 44px);
  height: 90%;
  min-height: 150px;
  width: 100%;
  order: 1;
  box-sizing: border-box;
  font-size: $fs--large;
  line-height: $lh--large;
  letter-spacing: 0;
  padding: 12px;
  overflow: auto;

  &--input {
    margin-bottom: 14px;
    margin-right: 14px;
  }

  &--output {
    margin-bottom: 4px;
    margin-left: 14px;
  }
}

@media screen and (min-width: 768px) {
  .box {
    display: block;
    order: 3;
    flex-basis: calc(50% - 22px - 12px);
    flex-grow: 1;

    &--input {
      order: 1;
    }
  }
}