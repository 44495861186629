@import '../../App';

.demo-overlay {
  background-color: $clr--dark-gray;
  height: 100%;
  z-index: 1000;
  display: none;

  &--active {
    display: block;
    height: 100%;
  }

  a {
    text-decoration: none;
    color: $clr__white;
  }
}

.aside {
  &__title, &__subtitle {
    color: $clr--white;
    font-size: $fs--small;

    padding: {
      left: $padding--small;
      right: $padding--small;
      top: $padding--normal;
    };

    a {
      color: $clr--yellow;
    }
  }

  &__title {
    //opacity: 0.6;
    font-weight: $fw--bold;
    margin-bottom: $margin--small;
    letter-spacing: 0;
    line-height: 19px;
  }

  &__subtitle {
    letter-spacing: 0.7px;
    line-height: 24px;
    padding-bottom: $padding--normal;
  }

  &__divider {
    display: flex;
    align-items: center;
    padding: {
      top: $padding--normal;
      bottom: $padding--normal;
      right: $padding--big;
      left: $padding--big;
    };
    cursor: pointer;
    width: fit-content;
  }

  &__button {
    margin-right: $margin__small;

    path {
      font-size: $fs--small !important;
      fill: $clr__white;
    }
  }
}

@media screen and (min-width: 768px) {
  .aside {
    &__subtitle, &__title {
      padding: {
        left: $margin--normal;
        right: $margin--normal;
      };
    }
  }
}

@media screen and (min-width: 1024px) {
  .demo-overlay {
    display: flex;
    position: fixed;
    height: 100%;
    top: 0;
    right: 0;
    width: 0;
    z-index: 1000;
    flex-direction: column;
    overflow-x: hidden;

    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .aside {
    &--active {
      width: 50%;
    }

    &__subtitle {
      margin-top: auto;
      padding-bottom: 0;
    }

    &__title {
      letter-spacing: $letter-spacing--small;
      line-height: 24px;
      font-weight: 400;
      margin-bottom: 0;
      padding: 0;
    }

    &__button {
      font-size: 20px;
    }

    &__divider {
      padding: {
        bottom: 10px;
        top: 25px;
      }
    }
  }
}