@font-face {
  font-family: "Roboto Regular";
  src: url("../utils/Roboto/Roboto-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Roboto Light";
  src: url("../utils/Roboto/Roboto-Light.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("../utils/Roboto_Mono/RobotoMono-VariableFont_wght.ttf") format("truetype");
  font-weight: 100 700;
}

$font-family: "Roboto Regular", sans-serif;
$font-family--light: "Roboto Light", sans-serif;
$font-family--code: "Roboto Mono", monospace;
$fs--small: 16px;
$lh--small: 19px;
$fs--middle: 24px;
$lh--middle: 29px;
$fs--large: 32px;
$lh--large: 38px;
$fs--h1: 40px;
$fs--h2: 30px;
$fs--h3: 20px;
$fw--normal: normal;
$fw--bold: bold;
$letter-spacing--small: 0.7px;

$clr--blue: #4DB6AC;
$clr--medium-gray: #9B9B9B;
$clr--light-gray: #F4F4F4;
$clr--dark-gray: #282828;

$clr--white: #FFFFFF;
$clr--red: #EF6650;
$clr--yellow: #E0B12B;
$clr--purple: #7268D8;

$clr--white--hover: rgba(255, 255, 255, 0.4);
$clr--red--hover: rgba(239, 102, 80, 0.4);
$clr--yellow--hover: rgba(224, 177, 43, 0.4);
$clr--purple--hover: rgba(114, 104, 216, 0.4);

$margin--small: 16px;
$margin--normal: 6%;
$margin--medium: 13%;
$margin--big: 21%;
$margin--extrabig: 28%;

$padding--very-small: 11px;
$padding--small: 16px;
$padding--normal: 20px;
$padding--big: 32px;

:root {
  font-family: $font-family;
  font-size: $fs--small;
  font-weight: $fw--normal;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: $clr--light-gray;
  color: $clr--dark-gray;
}

* {
  margin: 0;
  padding: 0;
}

html, body, #root {
  height: 100%;
}

.body--fixed {
  position: fixed;
}

.fixedPositionDropdown {
  overflow: hidden;
}

code {
  font-family: $font-family--code;
}

h1, h2, h3, p {
  word-break: break-word;
}

h1, h2, h3 {
  font-weight: $fw--bold;
  letter-spacing: 0;
  margin-bottom: 24px;
}

p {
  margin-bottom: $margin--normal;
}

h1 {
  font-size: $fs--h1;
  line-height: 45px;
}

h2 {
  font-size: $fs--h3;
  line-height: 24px;
}

h3 {
  font-size: $fs--h3;
  line-height: 24px;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: $clr--blue;
}

.arrow {
  svg {
    display: block;
    height: 100%;
    width: 100%;
  }
}

.red {
  &:hover {
    background-color: $clr--red--hover;
  }

  &--full {
    background-color: $clr--red;
  }
}

.purple {
  &:hover {
    background-color: $clr--purple--hover;
  }

  &--full {
    background-color: $clr--purple;
  }
}

.yellow {
  &:hover {
    background-color: $clr--yellow--hover;
  }

  &--full {
    background-color: $clr--yellow;
  }
}

.white {
  &:hover {
    background-color: $clr--white--hover;
  }

  &--full {
    background-color: $clr--white;
  }
}

.blue {
  &--full {
    background-color: $clr--blue;
  }
}

.section__viewport {
  margin: {
    left: $margin--small;
    right: $margin--small;
  }
  display: flex;
  flex-direction: column;
  //height: 100%;
  //height: calc(100% - 74px);
}

.notfound {
  margin: {
    top: 20px;
    left: $margin--small;
    right: $margin--small;
  }
}

.cookie-container {
  padding: {
    left: $margin--small;
    right: $margin--small;
  };
  box-sizing: border-box;
  align-items: center !important;

  a {
    color: $clr--yellow;
    &:hover {
      color: $clr--red;
    }
  }
}

.button--cookie {
  height: 32px;
  width: 135px;
  border: 3px solid $clr--purple;
  border-radius: 21px;
  padding: 0;
  background-color: $clr--purple;
  font-size: 14px;
  text-align: center;
  color: rgba(255, 255, 255, 0.95);
  font-weight: $fw--bold;
  margin: {
    right: 0;
    left: 0;
    //top: $margin--small;
    bottom: $margin--small;
  }
}

.text--cookie {
  margin: 0;
  width: 100% !important;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

.loading {
  margin: {
    top: 20px;
    bottom: 20px;
  }
  font-family: $font-family;
  font-size: $fs--small;
  letter-spacing: $letter-spacing--small;

  &--margin {
    margin: {
      right: $margin--small;
      left: $margin--small;
    }
  }
}

@media screen and (min-width: 768px) {
  .section__viewport, .loading--margin {
    margin: {
      left: $margin--normal;
      right: $margin--normal;
    }
  }

  .notfound {
    margin: {
      left: $padding--normal;
      right: $padding--normal;
    }
  }

  .cookie-container {
    padding: {
      left: $margin--normal;
      right: $margin--normal;
    };
  }
}

@media screen and (min-width: 1024px) {
  .notfound {
    font-size: $fs--h2;
    margin: {
      left: $margin--normal;
      right: $margin--normal;
    }
  }

  h2 {
    font-size: $fs--h2;
    font-weight: $fw--bold;
    letter-spacing: 0;
    line-height: 35px;
  }

  p {
    margin-bottom: $padding--big;

    &.big {
      font-size: $fs--h3;
      letter-spacing: 0;
      line-height: 28px;
    }
  }

  .text--cookie {
    width: calc(100% - 160px) !important;
  }

  .button--cookie {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1920px) {
  .section__viewport, .loading--margin, .notfound {
    margin: {
      left: $margin--normal;
      right: $margin--normal;
    }
  }

  .cookie-container {
    padding: {
      left: $margin--medium;
      right: $margin--medium;
    };
  }
}

/* Add margin to the page when cookie dialog is visible */
.page__bigmargin {
  margin-bottom: 275px;
}

@media screen and (min-width: 425px) {
  .page__bigmargin {
    margin-bottom: 225px;
  }
}

@media screen and (min-width: 768px) {
  .page__bigmargin {
    margin-bottom: 175px;
  }
}

@media screen and (min-width: 1024px) {
  .page__bigmargin {
    margin-bottom: 125px;
  }
}

@media screen and (min-width: 1440px) {
  .page__bigmargin {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 2056px) {
  .page__bigmargin {
    margin-bottom: 0;
  }
}