@import "../App";

.home {
  height: calc(100% - 75px);
}

@media screen and (min-width: 768px) {
  .home {
    height: calc(100% - 91px);
  }
}