@import "../App";

.transcription {
  height: 100%;
  margin: {
    top: $margin--small;
    bottom: $margin--small;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  &--first {
    background-color: $clr--white;
    padding: $padding--big;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $clr--red;
    border-radius: 100%;
    border: none;
    color: $clr--white;
    font-family: $font-family;
    order: 2;
    width: 44px;
    height: 44px;
    margin-top: 20px;
    font-size: 24px;

    svg {
      z-index: 1000;
    }
  }

  &__button--recording::after {
    display: flex;
    background: rgba(239,102,80, 0.5);
    border-radius: 50%;
    height: 52px;
    width: 52px;
    animation: pulsate 2s ease-out;
    animation-iteration-count: infinite;
    opacity: 0.0;
    content: "";
    z-index: 90;
    margin: auto;
    position: absolute;
  }

  &__button--firststart {
    width: 118px;
    height: 118px;
    text-transform: uppercase;
    line-height: 24px;
    font-size: 16px;
    letter-spacing: 2px;
    flex-direction: column;
  }

  &__microphone {
    font-size: 36px;
    margin-bottom: 8px;
  }

  &__text {
    color: $clr--dark-gray;
    font-family: $font-family--light;
    font-size: 24px;
    font-weight: 100;
    line-height: 28px;
    text-align: center;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .transcription {
    margin-bottom: $padding--normal;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;

    &--first {
      flex-direction: column;
      justify-content: center;
    }

    &__button {
      margin-top: 0;
    }

    &__button--firststart {
      margin-top: $padding--normal;
    }
  }
}

.fa-layers {
  margin-left: 1px !important;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0.0;
  }
}
